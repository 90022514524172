@import '../modules/_brand.scss';
@import '../bootstrap_overrides/_mixins.scss';

.front-page-hero{
	margin-bottom: 10rem;
	position: relative;
	&>.container{
		padding-right: 0;
		padding-left: 0;
		width: 100vw;
		max-width: 100vw;
		.row{
			margin-right: 0;
			margin-left: 0;
		}
	}
	&__content{
		padding-left: 0;
		padding-right: 0;
		position: relative;
		height: 100%;
	}
	&__text-wrapper{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		&.container{
			// padding-left: 0;
			// padding-right: 0;
		}
	}
	&__title{
		max-width: 20ch;

		
	}
	&__image{
		width: 100%;
		object-fit: cover;
		height: 100%;
		object-position: 35% 0;
	}
	.link{
		color: white;
		margin-top: 5rem;
	}
	&:before{
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0.2;
		background: linear-gradient(0.25turn, #2D4052, #FFFFFF);
		z-index: 1;
		mix-blend-mode: multiply;
	}
	.below-hero-content{
		padding: 7.5rem 0;
		background-color: $accentColor;
		h3{
			text-align: center;
			color: white;
			@include fluid-type(1.6rem, 2.2rem)
		}
		@media screen and (max-width: 1365px){
			padding: 5rem 0;
		}
	}
	@media screen and (max-width: 1365px){
		margin-bottom: 5rem;
	}
}
.front-page-content{
	&__wrapper{
		margin-bottom: 2.5rem;
		&.reversed{
			@media screen and (max-width: 767px){
				flex-flow: column-reverse nowrap!important;
				&>*{
					flex-basis: 0;
				}
			}
		}
	}
	&__image-wrapper, &__text-wrapper{
		&:first-child{
			padding-right: 0;
		}
		&:last-child{
			padding-left: 0;
		}
	}

	&__text-wrapper{
		padding: 7.5rem 5rem 5rem 5rem!important;
		&>*:not(:last-child){
			margin-bottom: 5rem;
		}
		@media screen and (max-width: 1365px){
			padding: 2.5rem!important;
			&>*:not(:last-child){
				margin-bottom: 2.5rem;
			}
		}
	}
	&__link{
		margin-top: auto;
	}
	&__text{
		margin-top: 0;
	}
	&__image-wrapper{
		padding-left: 0;
		padding-right: 0;
		img{
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
}